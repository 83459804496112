.student-vod-alert {
  max-width: 1200px !important;
  width: 70% !important;
  position: relative;
  top: 6%;

  .vod-link {
    a {
      text-decoration: none;
      color: #05B78C;
      font-weight: 500;
    }
  }

  .cancel-btn {
    display: flex;
    justify-content: right;
  }

  .vod-status {
    font-weight: 600;
  }

  .vod-description {
    border-radius: 5px;
    border: 2px solid rgba(238, 35, 55, 0.75);
    text-align: justify !important;
  }
}