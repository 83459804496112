.book-coach-page {
  .selected-time-label {
    font-weight: bold;
    color: #EE2337;
  }

  .instant-session-description {
    font-size: 20px;
    width: 65%;
    margin: 0 auto;
    display: block;
  }

  .instant-session-btn-row {
    width: 50%;
    margin: 0 auto;

    .cancel-session-btn {
      height: 60px;
      border-radius: 5px;
      border: 2px solid #EE2337;
      color: #EE2337;
      font-size: 20px;
      font-weight: 600;
      background: #101820;
    }

    .request-session-btn {
      height: 60px;
      border-radius: 5px;
      border: none;
      background: #EE2337;
      color: #D9D9D9;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .coach-calendar {
    .card {
      background: #101820;
      border: 2px solid #EE2337;
      border-radius: 5px;

      .coach-calendar-btn-main {
        margin: 0 auto;
        width: 80%;


        .calendar-discard-btn {
          background: rgba(238, 35, 55, 0);
          border: 2px solid #EE2337;
          color: #EE2337;
          font-size: 20px;
          font-weight: 600;
          height: 60px;
          margin-right: 2.5%;

          &:hover {
            background: #691C29;
            transition: 300ms;
          }
        }

        .calendar-save-btn {
          background: #691C29;
          border: 2px solid #EE2337;
          color: #D9D9D9;
          font-weight: 600;
          font-size: 20px;
          height: 60px;
          margin-right: 2.5%;

          &:hover {
            background: rgba(238, 35, 55);
            transition: 300ms;
          }
        }
      }
    }
  }

  .book-coach-slider {

    .rc-slider-track {
      background-color: #EE2337;
    }

    .rc-slider-handle {
      background: #EE2337;
      border: none;
      opacity: 1;
    }

    .rc-slider-handle:active {
      box-shadow: 0 0 5px #EE2337;
    }

    .rc-slider-handle:focus-visible {
      box-shadow: 0 0 0 3px #EE2337;
    }
  }

  .booking-mode-tab {
    .nav-link {
      color: rgb(5, 183, 140);
      font-size: 17px;
      font-weight: 500;

      &.active {
        color: white;
        background-color: rgb(5, 183, 140);
      }
    }
  }
}
