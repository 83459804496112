.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    margin: 0 0 10px;
    padding: 0;
  }

  &__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;

    &--selected {
      background: #fff;
      border-color: #aaa;
      color: black;
      border-radius: 5px;
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}
