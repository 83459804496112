.discount-banner {
  background: #0E282B;
  border-radius: 5px;
  border: 2px solid #0C574B;
  width: 460px;
  margin: 0 auto;
  display: block;
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: +90000;
  filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.44));

  .close-btn {
    color: #EE2337;
    position: absolute;
    cursor: pointer;
    right: 1.5%;
    top: 2%;
    font-weight: 700;
  }

  .bold-text {
    font-weight: 600;
  }

  .discount-title {
    color: #05B78C;
    font-size: 18px;
    font-weight: 700;
  }

  .discount-text {
    font-size: 13px;
  }

  .FPS-primary-button {
    font-size: 13px;
    height: 40px;
    width: 110px;
  }
}

@media (max-width: 650px) {
  .discount-banner {
    width: 300px;
  }
}