.terms-condition {
  .dashboard-box-outline {
    .terms-condition-text-main {
      text-align: justify;

      p {
        padding-bottom: 15px;
        font-size: 15px;
      }

      h4 {
        font-weight: 600;
      }

      .terms-condition-bold {
        text-transform: capitalize;
        font-weight: 700;
      }

      a {
        text-decoration: none;
        color: #EE2337;
        font-weight: 600;
      }
    }

  }
}