.student-vod-list-item {
  border: 2px solid rgba(238, 35, 55, 0.4);
  background: rgba(238, 35, 55, 0.1);
  border-radius: 5px;

  .vod-created {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.58);
  }

  .vod-thumbnail {
    img {
      border-radius: 5px;
      width: 350px;
    }
  }

  @media (max-width: 1399.5px) {
    .vod-thumbnail {
      img {
        width: 300px;
      }
    }
  }

  .vod-title {
    font-size: 25px;
    font-weight: 600;
  }

  .vod-link {
    a {
      text-decoration: none;
      color: #05B78C;
      overflow-wrap: break-word;
    }
  }

  .card {
    background: #101820;
    border: 2px solid rgba(238, 35, 55, 0.4);

    thead, tbody, tfoot, tr, td, th {
      border-bottom: 1px solid rgba(238, 35, 55, 0.25);
    }

    .btn {
      background: #101820;
      border: 1px solid #05B78C;
      color: #05B78C;
      transition: 400ms;

      &:hover {
        background: #05B78C;
        color: #101820;
        border: 1px solid #05B78C;
        transition: 400ms;
      }
    }
  }
}
