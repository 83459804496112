.submit-vod-page {
  .box-outline {
    border-radius: 5px;
    border: 2px solid #EE2337;

    .submit-vod-btn-main {
      width: 60%;
      margin: 0 auto;

      .dashboard-primary-outline-btn {
        margin-right: 2.5%;
      }

      .dashboard-primary-btn {
        margin-left: 2.5%;
      }
    }
  }

  .vod-text-main {
    display: flex;

    .vod-text {
      font-weight: 700;
      width: 200px;
    }
  }

  @media (max-width: 767.5px) {
    .vod-text-main {
      h4 {
        font-size: 15px;
      }

      .vod-text {
        width: 125px;
      }
    }
  }

  @media (max-width: 510px) {
    .vod-text-main {
      display: block;

      h4 {
        font-size: 15px;
      }

      .vod-text {
        width: unset;
      }
    }
  }

  .book-coach-slider {

    .rc-slider-track {
      background-color: #EE2337;
    }

    .rc-slider-handle {
      background: #EE2337;
      border: none;
      opacity: 1;
    }

    .rc-slider-handle:active {
      box-shadow: 0 0 5px #EE2337;
    }

    .rc-slider-handle:focus-visible {
      box-shadow: 0 0 0 3px #EE2337;
    }
  }
}
