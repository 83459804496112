.filter-outer {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.04);
  margin: 7px;
  border-radius: 10px;

  .filter-inner {
    padding: 5px 20px 5px 20px;
  }
}

.add-new-filter-btn {
  border: 2px solid #05B78C;
  color: #05B78C;
  background: #101820;

  &:hover {
    background: #101820;
    border: 2px solid #05B78C;
    color: #05B78C;
    filter: drop-shadow(0 0 0.3rem rgba(5, 183, 40, 0.4));
  }
}
