.coaches-page {
  .coaching-bg-img {
    background-image: linear-gradient(
                    rgba(16, 24, 32, 1),
                    rgba(16, 24, 32, 0.9),
                    rgba(16, 24, 32, 0.75),
                    rgba(16, 24, 32, 0.9),
                    rgba(16, 24, 32, 1),
                    rgba(16, 24, 32, 1)), url(
    '../../../public/assets/Coaching/valorant2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }


  .Coach-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }


  .result-pagination-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .Coaching-hero-section {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .coaching-game-description {
      font-weight: 500;
      font-size: 20px;
      width: 65%;
      margin: 0 auto;
      display: block;
    }

    .coaching-btn-main {
      display: flex;
      justify-content: space-between;

      .btn {
        font-weight: 500;
        font-size: 15px;
        width: 180px;
        height: 45px;
        text-align: left;
        background: rgba(16, 24, 32, 0.75);
        border: 2px solid #EE2337;
        border-radius: 5px;
        color: #D9D9D9;
        position: relative;
      }

      .dropdown-toggle::after {
        position: absolute;
        top: 15px;
        right: 10px;
      }

      .search-coach-name {
        .form-control {
          font-weight: 500;
          font-size: 15px;
          background: rgba(16, 24, 32, 0.75);
          width: 180px;
          height: 45px;
          text-align: left;
          color: #D9D9D9 !important;
          border-radius: 5px;
          border: 2px solid #EE2337;
        }
      }

      .search-btn-main {
        a {
          text-decoration: none;

          .search-btn {
            font-weight: 600;
            width: 180px;
            height: 45px;
            background: #EE2337;
            border-radius: 5px;
            color: #D9D9D9;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }

    }
  }

  .coaches-by-agents {
    .coaches-by-agents-title {
      text-align: center;
      font-size: 25px;
      font-weight: 800;
    }

    .agent-card-carousel {

      .react-multi-carousel-dot button {
        position: relative;
        top: 0px;
        background: #101820;
        border-color: #EE2337;
      }

      .react-multi-carousel-dot--active button {
        background: #EE2337;
      }

      .react-multiple-carousel__arrow--left {
        left: 0%;
      }

      .react-multiple-carousel__arrow::before {
        color: #EE2337;
        -webkit-text-stroke: 2px #EE2337;
        text-stroke: 2px #EE2337;
      }


      .react-multiple-carousel__arrow--right {
        right: 0%;
      }

      .react-multiple-carousel__arrow {
        background: none;
      }

      .react-multi-carousel-dot-list {
      }
    }
  }


  .ais-SearchBox-input {
    border-radius: 5px;
    background: #101820;
    border: 2px solid rgba(238, 35, 55);
  }

  .ais-SearchBox-form {
    background: #101820;
  }

  .ais-SearchBox-input {
    appearance: none;
    border-radius: 3px;
    box-shadow: 0 0 0 0.25rem rgba(238, 35, 55, 0.04);
    caret-color: #EE2337;
    color: #D9D9D9;
  }

  .ais-SearchBox-form::before {
    background: transparent url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23EE2337%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%228%22%3E%3C%2Fcircle%3E%3Cline%20x1%3D%2221%22%20y1%3D%2221%22%20x2%3D%2216.65%22%20y2%3D%2216.65%22%3E%3C%2Fline%3E%3C%2Fsvg%3E") repeat scroll 0% 0%;
  }

  .ais-SearchBox-loadingIndicator, .ais-SearchBox-reset {
    fill: #EE2337;
  }

  .ais-Pagination-link {
    background: #101820;
    border: 1px solid #EE2337;
    color: #EE2337;
    box-shadow: 0 0 0 0.25rem rgba(238, 35, 55, 0.04) inset;
  }

  .ais-HitsPerPage-select {
    background-image: url("data:image/svg+xml;utf8,<svg width=\"14\" height=\"24\" xmlns=\"http://www.w3.org/2000/svg\"><g stroke=\"%23EE2337\" stroke-width=\"2\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M13 7L7 1 1 7M1 17l6 6 6-6\"/></g></svg>"), linear-gradient(-180deg, #101820, #101820);
    background-color: #101820;
    color: #EE2337;
    border: 1px solid #EE2337;
  }

  .ais-HitsPerPage-select > option:not(:checked), .ais-SortBy-select > option:not(:checked) {
    color: #D9D9D9;
  }

  .ais-HitsPerPage-select:focus {
    box-shadow: 0 0 0 0.25rem rgba(238, 35, 55, 0.04);
  }



  .ais-Hits-item, .ais-InfiniteHits-item {
    align-items: center;
    background: #101820;
    box-shadow: 0 0 0 1px rgba(35, 38, 59, 0), 0 1px 3px 0 rgba(35, 38, 59, 0);
    display: flex;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    padding: 0.65rem;

  }

  thead, tbody, tfoot, tr, td, th {
    color: #D9D9D9;
  }

  .table > :not(caption) > * > * {
    border-bottom: 1px solid rgba(238, 35, 55, 0.7);
  }

  .coach-detail-row {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
  }
}


