.reset-password-page {

  .reset-password-card {
    width: 500px;

    .card {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
    }

    .card:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }
  }


  .reset-password {
    .reset-password-outline {
      margin: 0 auto;
      width: 900px;
      background: rgba(238, 35, 55, 0.1);
      border: 2px solid #EE2337;
      border-radius: 5px;
      position: relative;

      .reset-password-content {
        .reset-password-heading {
          font-weight: 700;
          font-size: 50px;
          text-transform: none;
        }

        .create-account {
          font-weight: 500;

          a {
            text-decoration: none;
            font-weight: 800;
            color: #05B78C;
          }
        }

        .reset-password-btn-main-section {
          display: flex;
          justify-content: end;

          .reset-password-btn-main {
            .reset-password-btn {
              color: #D9D9D9;
              font-size: 18px;
              text-align: center;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #691C29;
              border: 2px solid #EE2337;
              font-weight: 600;
              border-radius: 5px;

              &:hover {
                background: rgba(238, 35, 55);
                transition: 300ms;
              }
            }
          }
        }
      }
    }

    @media (max-width: 1033px) {
      .reset-password-outline {
        width: 650px;
        margin: 0 auto;

        .reset-password-content {
          .reset-password-social-icons-main {
            width: 95%;
          }
        }
      }
    }

    @media (max-width: 767.5px) {
      .reset-password-outline {
        width: 500px;
        margin: 0 auto;

        .reset-password-content {
          .reset-password-social-icons-main {
            width: 95%;

          }
        }

        .reset-password-content {
          width: 90%;
          margin: 0 auto;
        }
      }
    }

    @media (max-width: 540px) {
      .reset-password-outline {
        width: 360px;
        margin: 0 auto;

        .reset-password-content {
          .reset-password-social-icons-main {
            width: 95%;

          }
        }

        .reset-password-content {
          width: 90%;
          margin: 0 auto;


          .create-account {
            font-weight: 500;

            a {
              text-decoration: none;
              font-weight: 800;
              color: #05B78C;
            }
          }
        }
      }
    }
  }
}
