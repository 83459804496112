.pricing-page {
  min-height: 0;

  .pricing-sub-heading {
    font-size: 18px;
    font-weight: 500;
    width: 75%;
    margin: 0 auto;
    display: block;
  }


  .box-outline {
    margin: 0 auto;
    display: block;
    width: 800px;
    background: rgba(238, 35, 55, 0.1);
    border: 2px solid #EE2337;
    border-radius: 5px;
    position: relative;

    .standard-pricing-img {
      position: absolute;
      top: -22%;
      left: -8%;
    }

    .standard-pricing-content {
      margin-left: 40%;

      .price-small-text {
        font-size: 18px;
        font-weight: 600;
      }
    }


    .apex-package-img {
      position: absolute;
      top: -8%;
      right: -15%;
    }

    .apex-package-content {
      position: relative;
      margin-right: 32%;

      .apex-logo-img {
        position: absolute;
        top: -18%;
        left: 0%;
      }

      .apex-rank-main {
        width: 90%;

        .apex-rank-row {
          display: flex;
          justify-content: space-between;

          .apex-rank-price {
            font-size: 35px;
            font-weight: 700;
          }

          .apex-rank-tagline {
            font-size: 20px;
            font-weight: 500;
            color: #05B78C;
          }

        }
      }
    }

    .valorant-package-content {
      margin-left: 60%;

      .valorant-package-row {
        display: flex;
        justify-content: space-between;
        align-items: center;


        .valorant-price {
          font-weight: 700;
          font-size: 40px;

        }

        .games-quantity {
          font-size: 30px;
          font-weight: 600;
        }
      }
    }

    .valorant-package-img {
      position: absolute;
      left: -20%;
      top: -15%;
    }
  }


  .apex-box-main {
    position: relative;

    .apex-logo-img {
      position: absolute;
      top: 0%;
      left: 11%;

      img {
        opacity: 0.05;
      }
    }
  }

  .valorant-box-main {
    position: relative;

    .valorant-logo-img {
      position: absolute;
      top: -17.5%;
      left: 16%;

      img {
        opacity: 0.05;
      }
    }
  }

  @media (max-width: 767.5px) {
    .pricing-sub-heading {
      width: 50%;
    }

    .box-outline {
      width: 600px;
    }

    .standard-pricing-img {
      top: -15%;
      left: -5%;
    }

    .box-outline .standard-pricing-content {
      margin-left: 55%;

      h1 {
        font-size: 22px;
      }
    }

    .apex-package-img {
      top: -5%;
      right: -10%;
    }

    .apex-package-content {
      margin-right: 20%;

      .apex-logo-img {
        top: -15%;
        left: 5%;
      }
    }

    .valorant-package-content {
      margin-left: 40%;
    }

    .valorant-package-img {
      left: -15%;
      top: -10%;
    }
  }

  @media (max-width: 575.5px) {
    .standard-pricing-img {
      display: none;
    }
    .box-outline {
      width: 100%;
      .standard-pricing-content {
        margin-left: 0;
      }
    }
  }
}
