.facet-refinement-list {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 5px;

  .facet-title {
    margin-bottom: 5px;
    color: #EE2337;
    font-weight: 600;
  }

  .facet-option-list {
    .facet-option {
      margin-left: 10px;
    }
  }

  .filter-dropdown-button {
    .dropdown-toggle {
      background-color: #EE2337;
      border: none;
    }
  }
}
