.add-funds-alert {
  width: 70% !important;
  max-width: 1200px !important;

  .payment-button {
    width: 100%;
    padding: 14px;

    &.paypal {
      background-color: #ffc439;
      color: #343493;
    }

    &.stripe {
      background-color: black;
      color: white;
      font-size: 20px;
      padding: 13px;
    }
  }
}

@media (max-width: 1000px) {
  .add-funds-alert {
    width: auto !important;
  }
}
