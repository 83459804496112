.admin-coach-approval-tab {
  .coach-request-item {
    background: rgba(238, 35, 55, 0.1);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
