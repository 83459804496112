.about-us {
  .about-us-content {
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;

    .about-us-description {
      font-size: 30px;
      color: #05B78C;
      font-weight: 600;
    }
  }

  .about-us-logo {
    float: right;
  }

  @media (max-width: 991.5px) {
    .about-us-content {
      display: block;

      h1 {
        text-align: center;
      }

      .about-us-description {
        text-align-last: center;
        font-size: 25px;
      }
    }
    .about-us-logo.img-fluid {
      margin: 0 auto;
      display: block;
      float: none;
      max-width: 60%;
    }
  }


  .co-founder-msg-outline {
    width: 80%;
    margin: 0 auto;
    display: block;
    border: 2px solid #EE2337;
    border-radius: 5px;

    .co-founder-img-section {
      display: flex;

      .co-founder-img {
        .img-fluid {
          max-width: 350px;
        }
      }


      .co-founder-content {
        margin-top: 24%;

        .co-founder-title {
          font-weight: 700;
        }

        .co-founder-name {
          font-weight: 700;
          font-size: 50px;
          color: #EE2337;
        }

        .co-founder-game {
          font-weight: 700;
          font-size: 20px;
        }
      }
    }

    .co-founder-message {
      text-align: justify;
    }
  }

  @media (max-width: 1399.5px) {
    .co-founder-msg-outline {
      width: 100%;
    }
  }

  .about-us-welcome {
    padding-top: 150px;
    padding-bottom: 75px;

    .img-fluid {
      max-width: 80%;
      margin: 0 auto;
      display: block;
    }
  }

  @media (max-width: 991.5px) {
    .co-founder-msg-outline {
      .co-founder-img-section {
        display: block;

        .co-founder-img {
          .img-fluid {
            margin: 0 auto;
            display: block;
            max-width: 50%;
          }

        }
      }
    }
  }
}