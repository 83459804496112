.register-page {

  .register-card {
    width: 500px;

    .google-btn {
      cursor: pointer;
      margin: 15px auto;
      width: 100%;
      height: 42px;
      background-color: #4285f4;
      border-radius: 2px;
      box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
    }

    .google-icon-wrapper {
      position: absolute;
      margin-top: 1px;
      margin-left: 1px;
      width: 40px;
      height: 40px;
      border-radius: 2px;
      background-color: #fff;
    }

    .google-icon {
      margin: 10px auto;
      width: 40px;
      height: 18px;
    }

    .btn-text {
      float: left;
      color: #fff;
      font-size: 16px;
      letter-spacing: 0.2px;
      margin: 10px 0px 0px 85px;
    }

    .google-btn:hover {
      box-shadow: 0 0 6px #4285f4;
    }

    .google-btn:active {
      background: #1669f2;
    }

    .card {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
    }

    .card:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }
  }


  .register {
    .register-outline {
      margin: 0 auto;
      width: 1000px;
      background: rgba(238, 35, 55, 0.1);
      border: 2px solid #EE2337;
      border-radius: 5px;
      position: relative;

      .register-img {
        position: absolute;
        left: -7%;
        top: 10%;

        img {
          width: 500px;
        }
      }

      .register-content {
        width: 50%;
        margin-left: 45%;

        .register-heading {
          font-weight: 700;
          font-size: 45px;
          text-transform: none;
        }

        .create-account {
          font-weight: 500;

          a {
            text-decoration: none;
            font-weight: 800;
            color: #05B78C;
          }
        }

        .login-social-icons-main {
          margin: 0 auto;
          width: 70%;
          display: flex;
          justify-content: space-between;

          .social-icon {
            cursor: pointer;
            background: #691C29;
            border: 2px solid #EE2337;
            width: 60px;
            height: 60px;
            border-radius: 5px;
            filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0.4));

            &:hover {
              background: rgba(238, 35, 55);
              transition: 300ms;
            }

            .social-img {
              display: flex;
              flex-direction: column;
              height: 100%;
              justify-content: center;

              img {
                margin: 0 auto;
                display: block;
              }
            }

            &.disabled {
              cursor: not-allowed;
              background: rgba(238, 35, 55, 0.44);

              .social-img {
                opacity: 0.5;
              }
            }
          }
        }

        .section-breaker-main {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .section-breaker {
            width: 45%;
            height: 2px;
            background: #EE2337;
          }
        }

        .agree-for-condition {
          display: flex;

          .agree-for-condition-text {
            a {
              font-weight: 700;
              color: #05B78C;
              text-decoration: none;
            }
          }
        }

        .register-btn-main-section {
          display: flex;
          justify-content: space-between;

          .forgot-password {
            pointer-events: none;

            a {
              display: flex;
              flex-direction: column;
              height: 100%;
              justify-content: center;
              text-decoration: none;
              color: #05B78C;
              font-weight: 700;
            }
          }

          .register-btn-main {
            .register-btn {
              color: #D9D9D9;
              font-size: 18px;
              text-align: center;
              width: 150px;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #691C29;
              border: 2px solid #EE2337;
              font-weight: 600;
              border-radius: 5px;
              filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0.4));

              &:hover {
                background: rgba(238, 35, 55);
                transition: 300ms;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1199.5px) {
    .register {
      .register-outline {
        width: 650px;

        .register-img {
          position: absolute;
          left: -15%;
          top: 18.5%;

          img {
            width: 400px;
          }
        }

        .register-content {
          .login-social-icons-main {
            width: 90%;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }

  @media (max-width: 767.5px) {
    .register {
      .register-outline {
        width: 400px;

        .register-img {
          display: none;
        }

        .register-content {
          width: 90%;
          margin: 0 auto;

          .login-social-icons-main {
            width: 90%;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }

  @media (max-width: 425px) {
    .register {
      .register-outline {
        width: 350px;

        .register-img {
          display: none;
        }

        .register-content {
          width: 90%;
          margin: 0 auto;

          .login-social-icons-main {
            width: 90%;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
