.coach-packages-tab {
  thead, tbody, tfoot, tr, td, th {
    border-bottom: 1px solid rgba(255, 255, 255, 0);
  }

  .table-border-bottom {
    padding-bottom: 15px;
  }

  .game-name {
    font-size: 25px;
    font-weight: 600;
  }

  .my-packages-row {
    display: flex;

    .my-packages-title {
      width: 150px;
      font-weight: 500;
      color: #EE2337;
    }
  }

  .my-packages-rank-price {
    width: 10px;
  }
}