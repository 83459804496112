@import 'node_modules/react-big-calendar/lib/sass/styles';
@import 'node_modules/react-big-calendar/lib/addons/dragAndDrop/styles';

.rbc-calendar {
  .rbc-off-range-bg {
    background-color: rgba(255, 255, 255, 0.12);
  }

  .rbc-day-slot .rbc-events-container {
    margin: 0;
  }

  .rbc-today {
    background-color: rgba(238, 35, 55, 0.15);
  }

  .rbc-allday-cell {
    display: none;
  }

  .rbc-time-content {
    border-top: none;
  }

  .rbc-time-header-gutter {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .rbc-toolbar button {
    background: #101820;
    border: 1px solid #EE2337;
    color: #D9D9D9;
  }

  .rbc-toolbar-label {
    color: #EE2337;
  }

  .rbc-time-view {
    border: 2px solid rgba(238, 35, 55, 0.4);
    border-radius: 5px;
  }

  .rbc-time-content > * + * > *, .rbc-header + .rbc-header, .rbc-time-header-content {
    border-left: 1px solid rgba(238, 35, 55, 0.4);
  }

  .rbc-timeslot-group, .rbc-header {
    border-bottom: 1px solid rgba(238, 35, 55, 0.4);
  }

  .rbc-day-slot .rbc-time-slot {
    border-top: 1px solid rgba(238, 35, 55, 0.4);
  }

  .rbc-event {
    background: #05B78C;
    border: 1px solid #05B78C;
  }

  &.week-plan-calendar {
    .rbc-today {
      background-color: inherit;
    }

    .rbc-event {
      background: #284d73;
      border: 1px solid #3a5b7c;
    }
  }

  .rbc-event.rbc-background-event {
    background: rgba(139, 28, 211, 0.46);
    border: none;
    border-radius: 0;

    .rbc-event-label {
      display: none;
    }
  }

  .background-booking-event {
    background: #818181;
    border: 1px solid #afafaf;
  }

  .rbc-off-range-bg {
    background-color: transparent;
  }

  .rbc-on-range-bg {
    background-color: rgba(5, 183, 140, 0.15);
  }
}

.booking-calendar .rbc-time-header-gutter {
    border-bottom: 1px solid rgba(238, 35, 55, 0.4) !important;
}

