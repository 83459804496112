.app-home-page {
  .floating-big-logo {
    z-index: -1;
    user-select: none;
    pointer-events: none;
  }

  .hero-section {
    position: relative;

    .header-text {

      h1 {
        font-size: 60px;
        position: relative;
        left: 25%;
      }

      h3 {
        text-transform: uppercase;
        font-size: 35px;
        font-weight: 800;
        position: relative;
        left: 35%;
        filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0.4));
      }
    }

    @media (max-width: 1199.5px) {
      .header-text {

        h1 {
          position: relative;
          left: 17.5%;
        }

        h3 {
          left: 30%;
        }
      }
    }

    .floating-big-logo {
      margin-left: auto;
      margin-right: auto;
      display: block;
      position: absolute;
      top: -75px;
      right: -10%;
      max-width: 45%;
    }

    @media (max-width: 991.5px) {
      .header-text {

        h1 {
          position: unset;
          left: unset;
          text-align: center;
        }

        h3 {
          position: unset;
          left: unset;
          text-align: center;
        }
      }

      .floating-big-logo {
        position: absolute;
        top: 0px;
        right: -10%;
        max-width: 55%;
      }
    }

    @media (max-width: 767.5px) {
      .floating-big-logo {
        position: absolute;
        top: 0px;
        right: -15%;
        max-width: 65%;
      }
    }

    @media (max-width: 600px) {
      .floating-big-logo {
        position: absolute;
        top: 0px;
        right: -25%;
        max-width: 100%;
      }
    }
  }

  .all-games-section {
    .header-text-2 {
      display: flex;
      justify-content: space-between;
      width: 70%;
      margin: 0 auto;

      h3 {
        width: 90%;
        font-weight: 800;
        font-size: 35px;
        text-transform: uppercase;
      }

      .logo-text {
        position: relative;
        right: 0%;
      }
    }

    @media (max-width: 991.5px) {
      .header-text-2 {
        width: 100%;
      }
    }

    @media (max-width: 767.5px) {
      .header-text-2 {
        display: block;
        justify-content: unset;

        h3 {
          width: 100%;
          font-weight: 800;
          font-size: 35px;
          text-transform: uppercase;
          text-align: center;
        }

        .logo-text {
          position: unset;
          right: unset;
          padding-top: 5%;

          img {
            margin: 0 auto;
            display: block;
          }
        }
      }
    }
  }

  .game-btn-main {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;


    .btn {
      padding-top: 10px;
      padding-bottom: 10px;
      width: 175px;
      border-radius: 5px;
      border: 2px solid #05B78C;
      color: #05B78C;
      font-weight: 600;
      text-align: center;
      transition: 300ms;
      background: rgba(5, 183, 140, 0.1);
      filter: drop-shadow(0 0 0.75rem rgba(5, 183, 140, 0.4));

      &:hover {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 175px;
        border-radius: 5px;
        border: 2px solid #05B78C;
        color: #05B78C;
        font-weight: 600;
        text-align: center;
        filter: drop-shadow(0 0 0.3rem rgba(5, 183, 140, 0.5));
        transition: 300ms;
        background: rgba(5, 183, 140, 0);
      }
    }
  }

  @media (max-width: 1400px) {
    .game-btn-main {
      width: 100%;
    }
  }

  @media (max-width: 388px) {
    .game-btn-main {
      .btn {
        padding-top: 5px;
        padding-bottom: 5px;
        width: 145px;
        border-radius: 5px;
        border: 2px solid #05B78C;
        color: #05B78C;
        font-weight: 600;
        text-align: center;
        background: #101820;
        transition: 400ms;
        font-size: 15px;
      }
    }
  }


  .JoinFPS-main {
    position: relative;

    .joinFps-first-section {
      width: 80%;
      margin: 0 auto;
      display: block;
    }

    .joinFPS-description {
      text-align: justify;
      font-size: 25px;
      width: 95%;
    }

    @media (max-width: 768px) {
      .joinFPS-description {
        font-size: 20px;
        text-align: justify;
        text-align-last: center;
        margin: 0 auto;
        width: 100%;
      }
    }

    .joinFPS-digits {
      display: flex;
      justify-content: space-around;
      font-size: 35px;
      text-align: center;
      align-items: center;
      height: 100%;

      .digit-item {
        font-weight: 700;
      }

      .digit-item-label {
        font-size: 20px;
      }
    }

    .register-section-main {
      .registration-section-bg-pattern {
        display: block;

        .bg-pattern-1 {
          position: absolute;
          z-index: +10;
          right: 0%;
          top: 10%;

          img {
            width: 100%;
            max-width: 1000px;
          }
        }

        .bg-pattern-2 {
          position: absolute;
          z-index: +10;
          left: 0%;
          top: 40%;

          img {
            width: 100%;
            max-width: 600px;
          }
        }
      }

      @media (max-width: 1100px) {
        .registration-section-bg-pattern {
          display: none;
        }
      }

      .register-section {
        .FPSLounge-subheading {
        }

        .secondary-color {
          font-weight: 600;
          font-size: 20px;
        }

        .register-section-content-main {
          position: relative;
          z-index: +500;

          .register-section-img {
            .img-fluid {
              max-width: 80%;
              position: relative;
              right: -15%;
            }
          }

          @media (max-width: 767.5px) {
            .register-section-img {
              .img-fluid {
                right: -11%;
                top: 5%;
              }
            }
          }

          .register-section-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;

            .register-now-btn-main {
              width: 200px;

              a {
                text-decoration: none;

                .register-now-btn {
                  text-align: center;
                  padding: 20px 0px 20px 0px;
                  background: #691C29;
                  border: 2px solid #EE2337;
                  font-weight: 600;
                  border-radius: 5px;
                  color: #D9D9D9;
                  transition: 300ms;
                  filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0.4));

                  &:hover {
                    background: rgba(238, 35, 55);
                    transition: 300ms;
                  }
                }
              }
            }
          }

          @media (max-width: 767.5px) {
            .register-section-content {
              text-align: center;

              .register-now-btn-main {
                margin: 0 auto;
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .ViewCoaches {
    .coach-card-carousel {

      .react-multi-carousel-dot button {
        position: relative;
        top: 0px;
        background: #101820;
        border-color: #EE2337;
      }

      .react-multi-carousel-dot--active button {
        background: #EE2337;
      }

      .react-multiple-carousel__arrow--left {
        left: -1%;
      }

      .react-multiple-carousel__arrow::before {
        color: #EE2337;
        -webkit-text-stroke: 2px #EE2337;
        text-stroke: 2px #EE2337;
      }


      .react-multiple-carousel__arrow--right {
        right: -1%;
      }

      .react-multiple-carousel__arrow {
        background: none;
      }

      .react-multi-carousel-dot-list {
      }
    }

    .view-coaches-btn-main {
      display: flex;
      justify-content: center;

      a {
        text-decoration: none;

        .view-coaches-btn {
          width: 200px;
          height: 60px;
          justify-content: center;
          display: flex;
          align-items: center;
          background: #691C29;
          border: 2px solid #EE2337;
          border-radius: 5px;
          font-weight: 600;
          color: #D9D9D9;
          transition: 300ms;
          filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0.4));

          &:hover {
            background: rgba(238, 35, 55);
            transition: 300ms;
          }
        }
      }
    }
  }


  .infoSection-one {
    .info-section-one-content-desktop {
      position: relative;
      display: block;

      .FPSLounge-heading {
        width: 60%;
        position: absolute;
        top: 25%;
      }

      .info-section-one-description {
        position: relative;
        text-align: justify;
        font-weight: 400;
        font-size: 18px;
        margin-top: 25px;
      }

      .infoSectionOne-img {
        .img-fluid {
          max-width: 90%;
        }
      }
    }

    .info-section-one-content-mobile {
      display: none;
    }

    @media (max-width: 992px) {
      .info-section-one-content-desktop {
        display: none;
      }

      .info-section-one-content-mobile {
        display: block;

        .FPSLounge-heading {
          width: 100%;
        }

        .info-section-one-description {
          position: relative;
          text-align: justify;
          text-align-last: center;
          font-weight: 400;
          font-size: 18px;
        }

        .infoSectionOne-img {
          .img-fluid {
            max-width: 75%;
            margin: 0 auto;
            display: block;
          }
        }
      }
    }

  }

  .infoSection-two {
    .info-section-two-content {
      position: relative;
      display: block;

      .FPSLounge-heading {
        text-align: right;
        margin-top: 20%;
        width: 60%;
        position: absolute;
        left: 40%;
      }

      .info-section-two-description {
        position: relative;
        text-align: justify;
        font-weight: 400;
        font-size: 18px;
        margin-top: 25px;
        text-align-last: right;
        width: 75%;
      }

      .infoSectionOne-img {
        .img-fluid {
          max-width: 90%;
        }
      }
    }

    .info-section-two-content-mobile {
      display: none;
    }

    @media (max-width: 992px) {
      .info-section-two-content {
        display: none;

      }

      .info-section-two-content-mobile {
        display: block;

        .FPSLounge-heading {
          width: 100%;
        }

        .info-section-two-description {
          position: relative;
          text-align: justify;
          text-align-last: center;
          font-weight: 400;
          font-size: 18px;
        }

        .infoSectionTwo-img {
          .img-fluid {
            max-width: 75%;
            margin: 0 auto;
            display: block;
          }
        }
      }

    }
  }
}
