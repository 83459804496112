.react-datetimerange-picker {
  display: inline-flex;
  position: relative;

  svg {
    stroke: white;
  }

  .react-datetimerange-picker__wrapper {
    padding: 10px;
    border-radius: 6px;
  }

  .react-datetimerange-picker__range-divider {
    padding: 0 15px;
  }

  input {
    color: white;
  }
}
