@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~bootstrap/scss/bootstrap";
@import "src/components/algolia/algolia-styles";
.firebase-emulator-warning {
  display: none;
}

.react-select-container {
  .react-select__control {
    background-color: var(--bg-secondary);
    border-color: var(--border-color);
    transition: none;

    &:hover {
      border-color: var(--border-color);
    }
  }

  .react-select__menu {
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-color);
  }

  .react-select__option {
    background-color: var(--bg-secondary);

    &:hover {
      background-color: var(--bg-primary);
    }
  }

  .react-select__indicator-separator {
    background-color: var(--border-color);
  }

  .react-select__placeholder,
  .react-select__single-value {
    color: var(--text-primary);
  }
}
