.coach-earning-list-item {
  .card {
    background: #101820;
    border: 2px solid rgba(238, 35, 55, 0.4);

    thead, tbody, tfoot, tr, td, th {
      border-bottom: 0px solid rgba(238, 35, 55, 0.25);
    }
  }

  .coach-earning-row {
    display: flex;
    justify-content: space-between;

    .coach-earning-title {
      font-weight: 500;
      color: #EE2337;
    }

    .coach-earning-info {
      text-align: right;
    }
  }

  @media (max-width: 767.5px) {
    .coach-earning-row {
      display: block;

      .coach-earning-info {
        text-align: left;
      }
    }
  }
}
