.footer-description {
  text-align: justify;
  font-size: 15px;
  width: 60%;
}


@media (max-width: 767.5px) {
  .footer-description {
    width: 100%;
    text-align-last: center;
  }

  .footer-logo {
    img {
      margin: 0 auto;
      display: block;
    }

  }
}

.footer-links-main {
  display: flex;
  justify-content: space-between;
}

.footer-title {
  color: #EE2337;
  font-weight: 600;
}

.footer-links {
  a {
    text-decoration: none;
    color: #D9D9D9;
    transition: 200ms;
  }

  a:hover {
    color: #EE2337;
    transition: 200ms;
  }
}

.un-clickable {
  pointer-events: none;
}

.h-rule {
  height: 2px;
  width: 100%;
  border-bottom: 2px solid #EE2337;
}
