.firebase-messaging-wrapper {
  .messaging-notification {
    z-index: 4324234342;
    position: fixed;
    top: 20px;
    right: 20px;
    min-width: 400px;

    .notification-header-container {
      background-color: #363636;
      color: white;
      .notification-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}
