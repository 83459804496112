.student-gain-list-item {

  .student-gain-row {
    display: flex;
    justify-content: space-between;
  }

  .student-gain-title {
    font-weight: 500;
    color: #EE2337;
  }

  .student-gain-info {
    color: #D9D9D9;
  }

  .invoice-btn {
    display: flex;
    justify-content: right;
  }

  @media (max-width: 991.5px) {
    .invoice-btn {
      display: flex;
      justify-content: left;
    }
  }

}