.coach-booking-list-item {
  .card {
    background: rgba(238, 35, 55, 0.1);
    border: 2px solid rgba(238, 35, 55, 0.4);

    .card-body {
      padding: 0px 0px 0px 0px;
    }

    thead, tbody, tfoot, tr, td, th {
      border-bottom: 1px solid rgba(255, 255, 255, 0);
      padding-top: 15px;
    }

    .student-info-row {
      display: flex;

      .dashboard-student-img img {
        border-radius: 50px;
      }

      .student-info {
        margin-left: 5%;
      }

      .student-name {
        font-size: 25px;
        font-weight: 600;
      }

      .booking-mode {
        font-weight: 600;
      }
    }


  }

  .booking-request-col {
    padding-left: 2.5%;
    border-left: 1px solid rgba(238, 35, 55, 0.4);
  }

  @media (max-width: 767.5px) {
    .booking-request-col {
      padding-left: 0%;
      border-left: 0px solid rgba(238, 35, 55, 0.4);
    }
  }

  .booking-request-main-row {
    display: flex;
  }

  .booking-request-info-row {
    display: flex;

    .booking-request-title {
      width: 200px;
      font-weight: 500;
      color: #EE2337;
    }
  }

  .booking-request-row {
    display: flex;
  }

  .booking-request-bold {
    font-weight: 500;
    width: 90px;
    color: #EE2337;
  }

  .booking-request-time {
    padding-bottom: 10px;
  }
}
