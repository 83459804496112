.ring-container {
  position: relative;

  .circle {
    width: 15px;
    height: 15px;
    background-color: #00c46f;
    border-radius: 50%;
  }

  .ringring {
    border: 1px solid #00c46f;
    border-radius: 30px;
    height: 15px;
    width: 15px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0.0
  }

  &.offline {
    .circle {
      background-color: #e14e31;
    }

    .ringring {
      display: none;
    }
  }

  &.busy {
    .circle {
      background-color: #c49d00;
    }

    .ringring {
      border: 1px solid #c49d00;
    }
  }

  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0.0;
    }
    50% {
      opacity: 1.0;
    }
    100% {
      transform: scale(2.2, 2.2);
      opacity: 0.0;
    }
  }
}
