.coach-hit {


  .coach-card-offline {
    width: 300px;
    height: 480px;
    margin: 0 auto;
    display: block;
    position: relative;
    z-index: +500;
    overflow: hidden;

    &:hover {
      filter: drop-shadow(0 0 0.5rem rgba(238, 35, 55, 0.5));
    }


    .coach-card-outline {
      position: absolute;
      top: 0;
      z-index: +1000;
      width: 300px;
      height: 480px;
      border: 2px solid #EE2337;
      border-radius: 5px;
      background-color: #101820;


      .coach-card-img {
        cursor: pointer;

        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          border-bottom: 2px solid #EE2337;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }

      .game-name {
        position: absolute;
        z-index: +1100;
        height: 35px;
        width: 150px;
        background: #101820;
        border-radius: 5px;
        border: 2px solid #253E5B;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        left: 75px;
        top: 182.5px;
      }


      .coach-card-content {
        width: 85%;
        margin: 0 auto;
        display: block;

        .coach-point {
          height: 90px;

          ul {
            padding-left: 0px;

            li {
              list-style: none;
              font-size: 14px;
            }

            li::before {
              content: '';
              display: inline-block;
              margin-right: 10px;
              height: 12px;
              width: 12px;
              background-image: url("../../public/assets/squares-four.svg");

            }
          }
        }

        .coach-detail-row {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          filter: drop-shadow(0 0 0.5rem rgba(238, 35, 55, 0));
        }
      }

      .coach-name-main {
        height: 75px;
        display: flex;
        justify-content: center;
        padding-top: 35px;


        .coach-name {
          cursor: pointer;
          font-size: 20px;
        }

        .coach-language {
          padding-left: 20px;
        }

        .online-indicator {
          padding-left: 20px;
          padding-top: 5px;
        }
      }

      .coach-rating {
        text-align: center;
      }

      .coach-card-profile-click {
        width: 296px;
        height: 416px;
        position: absolute;
        background-color: rgba(16, 24, 32, 0);
        z-index: +500;
        cursor: pointer;
      }

      .coach-card-btn-main {
        display: flex;
        justify-content: space-between;
        align-items: center;


        .dashboard-secondary-btn {
          transition: 400ms;
          position: relative;
          z-index: +9000;
          background-color: #101820;
          filter: drop-shadow(0 0 0.35rem rgba(5, 183, 140, 0.2));

          &:hover {
            transition: 400ms;
            background: #0C574B;
          }
        }

      }


      .coach-price {
        color: #05B78C;
        font-weight: 600;
      }
    }
  }


  .coach-card-online {
    width: 300px;
    height: 480px;
    margin: 0 auto;
    display: block;
    position: relative;
    z-index: +500;
    overflow: hidden;
    filter: drop-shadow(0 0 0.5rem rgba(0, 196, 111, 0.3));

    &:hover {
      filter: drop-shadow(0 0 0.5rem rgba(0, 196, 111, 0.5));
    }


    .coach-card-outline {
      position: absolute;
      top: 0;
      z-index: +1000;
      width: 300px;
      height: 480px;
      border: 2px solid #00c46f;
      border-radius: 5px;
      background-color: #101820;


      .coach-card-img {
        cursor: pointer;

        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          border-bottom: 2px solid #00c46f;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }

      .game-name {
        position: absolute;
        z-index: +1100;
        height: 35px;
        width: 150px;
        background: #101820;
        border-radius: 5px;
        border: 2px solid #253E5B;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        left: 75px;
        top: 182.5px;
      }

      .coach-card-content {
        width: 85%;
        margin: 0 auto;
        display: block;

        .coach-point {
          height: 90px;

          ul {
            padding-left: 0px;

            li {
              list-style: none;
              font-size: 14px;
            }

            li::before {
              content: '';
              display: inline-block;
              margin-right: 10px;
              height: 12px;
              width: 12px;
              background-image: url("../../public/assets/squares-four.svg");

            }
          }
        }

        .coach-detail-row {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          filter: drop-shadow(0 0 0.5rem rgba(238, 35, 55, 0));
        }
      }

      .coach-name-main {
        height: 75px;
        display: flex;
        justify-content: center;
        padding-top: 35px;


        .coach-name {
          cursor: pointer;
          font-size: 20px;
        }

        .coach-language {
          padding-left: 20px;
        }

        .online-indicator {
          padding-left: 20px;
          padding-top: 5px;
        }
      }

      .coach-rating {
        text-align: center;
      }

      .coach-card-profile-click {
        width: 296px;
        height: 416px;
        position: absolute;
        background-color: rgba(16, 24, 32, 0);
        z-index: +500;
        cursor: pointer;
      }

      .coach-card-btn-main {
        display: flex;
        justify-content: space-between;
        align-items: center;


        .dashboard-secondary-btn {
          transition: 400ms;
          position: relative;
          z-index: +9000;
          background-color: #101820;
          filter: drop-shadow(0 0 0.35rem rgba(5, 183, 140, 0.2));

          &:hover {
            transition: 400ms;
            background: #0C574B;
          }
        }

      }


      .coach-price {
        color: #05B78C;
        font-weight: 600;
      }
    }
  }


  .hit-coach-name {
    font-weight: 500;
    font-size: 25px;
  }
}


