.respond-to-vod-page {
  * {
    color: #D9D9D9;
  }

  .respond-to-vod-button-row {
    width: 70%;
    margin: 0 auto;

    .dashboard-primary-outline-btn {
      margin-right: 2.5%;
    }

    .dashboard-primary-btn {
      margin-left: 2.5%;
    }
  }

  .coach-vod-list-item {
    border: 2px solid rgba(238, 35, 55, 0.4);
    background: rgba(238, 35, 55, 0.1);
    border-radius: 5px;

    .vod-thumbnail {
      img {
        border-radius: 5px;
      }
    }

    .vod-title {
      font-size: 25px;
      font-weight: 600;
    }

    .coach-vod-row {
      display: flex;
    }

    .vod-link {
      a {
        text-decoration: none;
        color: #05B78C;
      }
    }
  }
}
