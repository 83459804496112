.admin-app-home-page {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .add-game-card {
    width: 60vw;

    .btn-text {
      float: left;
      color: #fff;
      font-size: 16px;
      letter-spacing: 0.2px;
      margin: 10px 0px 0px 85px;
    }

    .card {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
    }

    .card:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }
  }

  .game-info {
    border-right: 1px solid rgba(0, 0, 0, 0.175);
  }

  .bi {
    cursor: pointer;
  }

  .bi-plus-circle-dotted {
    font-size: 1.5rem;
  }
}
