.coach-register-page {

  .register-card {
    width: 500px;

    .google-btn {
      cursor: pointer;
      margin: 15px auto;
      width: 100%;
      height: 42px;
      background-color: #4285f4;
      border-radius: 2px;
      box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
    }

    .google-icon-wrapper {
      position: absolute;
      margin-top: 1px;
      margin-left: 1px;
      width: 40px;
      height: 40px;
      border-radius: 2px;
      background-color: #fff;
    }

    .google-icon {
      margin: 10px auto;
      width: 40px;
      height: 18px;
    }

    .btn-text {
      float: left;
      color: #fff;
      font-size: 16px;
      letter-spacing: 0.2px;
      margin: 10px 0px 0px 85px;
    }

    .google-btn:hover {
      box-shadow: 0 0 6px #4285f4;
    }

    .google-btn:active {
      background: #1669f2;
    }

    .card {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
    }

    .card:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }
  }

  .BecomeACoach {
    .become-coach-description {
      width: 65%;
      margin: 0 auto;
      display: block;
    }

    .become-coach-outline {
      min-height: 500px;
      margin: 0 auto;
      width: 900px;
      background: rgba(238, 35, 55, 0.1);
      border: 2px solid #EE2337;
      border-radius: 5px;
      position: relative;

      .become-coach-img {
        position: absolute;
        right: 0%;
        top: -12.5%;

        img {
          width: 325px;
        }
      }

      .become-coach-content {
        margin: auto 50px;
      }

      .become-coach-fields-main {

        .form-field-row {
          display: flex;

          & > * {
            width: 100%;
          }
        }

        .coach-idea {

          .form-control {
            height: unset;
          }

        }

        .agree-for-condition {
          display: flex;

          .agree-for-condition-text {
            a {
              font-weight: 700;
              color: #05B78C;
              text-decoration: none;
            }
          }
        }

        .application-submit {
          margin-top: 18%;

          h3 {
            font-weight: 700;
          }

          p {
            color: #D9D9D9;
            font-size: 18px;
          }

          .FPS-primary-button {
            width: 300px !important;
          }
          .btn-right {
            margin-right: 48px
          }
        }

      }

      .become-coach-btn-section {
        display: flex;
        justify-content: space-between;

        .become-coach-btn-main {
          .become-coach-btn {
            color: #D9D9D9;
            font-size: 18px;
            text-align: center;
            width: 250px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #691C29;
            border: 2px solid #EE2337;
            font-weight: 600;
            border-radius: 5px;

            &:hover {
              background: rgba(238, 35, 55);
              transition: 300ms;
            }
          }
        }
      }
    }

    @media (max-width: 1033px) {
      .become-coach-outline {
        width: 650px;
        margin: 0 auto;

        .become-coach-img {
          position: absolute;
          right: -2.5%;
          top: 12.5%;

          img {
            width: 290px;
          }
        }

        .become-coach-fields-main {
          .form-field-row {
            display: block;
          }
        }
      }
    }

    @media (max-width: 767.5px) {
      .become-coach-outline {
        width: 500px;
        margin: 0 auto;

        .become-coach-img {
          display: none;

          img {
            display: none;
          }
        }

        .become-coach-content {
          width: 90%;
          margin: 0 auto;
        }
      }
    }

    @media (max-width: 540px) {
      .become-coach-outline {
        width: 360px;
        margin: 0 auto;


        .become-coach-content {
          width: 90%;
          margin: 0 auto;
        }
      }
    }
  }
}
