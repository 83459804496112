.app-navbar {
  position: relative;
  z-index: +90000;
  /*.nav-link {
    margin: 0 10px;
  }*/

  .navbar-desktop {
   transition: background 0.3s;
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;

    &.scrolled {
        background: #282F35;
    }


    .navbar-desktop-main {
      display: flex;
      justify-content: center;
      align-items: center;

      .navbar-brand {
        margin-right: 4vw;
      }

      .navbar-desktop-items {
        display: flex;
        justify-content: space-around;
        flex: 1;

        .navbar-add-fund-main {
          height: 45px;
        }

        .navbar-add-fund {
          background: rgba(238, 35, 55, 0);
          border: 2px solid #EE2337;
          border-radius: 5px;
          color: #EE2337;
          font-weight: 600;
          height: 45px;
          display: flex;
          justify-content: center;

          &:hover {
            background: rgba(105, 28, 41, 0);
            transition: 300ms;
          }

          .nav-link {
            padding-left: 10px;
            padding-right: 10px;
          }

          .dropdown-menu {
            margin-top: 18px;
            font-size: 13px;
          }
        }

        .nav-link {
          padding-top: 10px;
          font-size: 13px;

          &:hover {
            color: #EE2337;
            transition: 400ms;
          }
        }
      }

      @media (max-width: 1399.5px) {
        .navbar-desktop-items {
          flex: 1;
        }
      }

      @media (max-width: 1210px) {
        .navbar-desktop-items {
          flex: 1;
        }
      }

      .navbar-primary-btn {
        background: #EE2337;
        border-radius: 5px;
        transition: 400ms;
        height: 45px;


        .nav-link {
          color: #D9D9D9;
          font-weight: 600;
          padding: 12.5px 10px 10px 10px;
        }

        .nav-link:hover {
          color: #D9D9D9;
        }

        ::after {
          margin-left: 10px;
        }

        &:hover {
          transition: 400ms;
        }

        .dropdown-menu {
          font-size: 13px;
        }
      }


      .navbar-desktop-login {

        .nav-link {
          padding: 12.5px 30px 10px 30px;
          font-size: 13px;
        }
      }

      .coach-dashboard-btn {

        .nav-link {
          padding: 13px 20px 10px 20px;
        }

        .nav-link:hover {
          color: #D9D9D9;
          font-weight: 500;
        }

      }


    }

    .nav-link {
      color: #D9D9D9;
      font-weight: 400;
      transition: 400ms;
    }
  }

  @media (max-width: 1200px) {
    .navbar-desktop {
      display: none;
    }
  }

  .navbar-mobile {
    display: none;
  }

  @media (max-width: 1200px) {
    .navbar-mobile {
      display: block;

      .navbar-primary-btn {
        background: #EE2337;
        border: 2px solid #EE2337;
        border-radius: 5px;
        height: 50px;
        width: 100%;
        color: #D9D9D9;
        text-align: center;
        position: relative;
        z-index: +5000;

        &:hover {
          background: #EE2337;
          transition: 300ms;
          color: #D9D9D9;
        }

        .nav-link {
          padding: 10px;
          color: #D9D9D9;
        }

        .nav-link:hover {
          color: #D9D9D9 !important;
        }

        .dropdown-menu {
          margin-top: 2.5%;
        }
      }

      .navbar-add-fund {
        background: rgba(238, 35, 55, 0);
        border: 2px solid #EE2337;
        border-radius: 5px;
        filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0.4));
        height: 50px;
        width: 100%;
        color: #D9D9D9;
        text-align: center;
        position: relative;
        z-index: +5000;

        &:hover {
          background: rgba(105, 28, 41, 0);
          transition: 300ms;
          color: #EE2337;
        }

        .nav-link {
          padding: 10px;
        }

        .dropdown-menu {
          margin-top: 2.5%;
        }
      }

      .navbar-color {
         background: transparent;

         &.scrolled {
             background: #282F35;
         }
      }


      .sign-in-btn {
        background: #EE2337;
        height: 45px;
        text-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 5px;
      }

      .navbar-mobile-items {
        .nav-link {
          color: #D9D9D9;
          text-align: center;

          &:hover {
            color: #EE2337;
            transition: 400ms;
          }
        }
      }
    }
  }

}
