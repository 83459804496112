.rank-rate-list {
  .rank-rate-list-item {
    background-color: rgba(255, 255, 255, 0.04);
    padding: 15px;
    margin: 7px;
    border-radius: 10px;
    .remove-icon{
      margin-left: 10px;
    }
  }
}
