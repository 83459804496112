.admin-coach-tab {
  .table-row-bg {
    height: 50px;
  }

  .name-td {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .buttons-td {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .add-new-coaches-table {
    thead, tbody, tfoot, tr, td, th {
      border-bottom: 0px solid rgba(238, 35, 55, 0.25);

    }
  }
}
