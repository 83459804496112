.gradient-bg {
    position: relative;
}

.gradient-bg::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.60) 0%, rgba(19, 26, 33, 0.90) 72.55%);
}
