.slot-availability-view {
  .availability-table {
    color: white;
    font-size: 13px;
    border-color: #212529;

    tr {
      user-select: none;

      td {
        padding: 2px 13px;
      }

      &.available {
        background-color: rgba(122, 231, 122, 0.24);
      }

      &.booked {
        background-color: rgba(217, 110, 110, 0.3);
        height: 10px;
      }

      &.selected {
        background-color: rgb(124, 253, 151);
        color: black;
      }
    }
  }
}
