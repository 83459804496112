.admin-coach-tab {
  .table-row-bg {
    height: 50px;
  }

  .name-td {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .buttons-td {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .add-new-coaches-table {
    thead, tbody, tfoot, tr, td, th {
      border-bottom: 0px solid rgba(238, 35, 55, 0.25);

    }
  }

  .nav {
    border-bottom: none !important;

    .nav-item {
      border-bottom: 2px solid #EE2337;
    }

    .nav-tabs {
      .nav-link {
        color: #EE2337;
        font-size: 20px;
        font-weight: 600;
      }
    }

    .tab-content {
      width: 100%;
    }

    .nav-link:hover {
      border: 1px solid #EE2337;
    }

    .nav-link.active {
      background: #EE2337;
      color: #101820;
      border: none;
      border-bottom: 2px solid #EE2337;;
    }
  }
}
