.coach-withdrawal-list-item {
  .card {
    background: #101820;
    border: 2px solid rgba(238, 35, 55, 0.4);

    thead, tbody, tfoot, tr, td, th {
      border-bottom: 0px solid rgba(238, 35, 55, 0.25);
    }
  }

  .coach-withdrawal-row {
    .coach-withdrawal-title {
      font-weight: 500;
      color: #EE2337;
    }
  }

  @media (max-width: 767.5px) {
    .coach-withdrawal-row {
      display: block;
    }
  }
}
