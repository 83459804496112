.chat-page {
  #chat-section {
    height: 100% !important;

    .ce-chat-engine {
      border: 1px solid #ED2337;
      border-radius: 5px !important;
      overflow: hidden !important;
    }

    .ce-chat-form-title {
      color: #AAA6A8;
      font-weight: 700 !important;
    }


    .ce-danger-button:hover {
      border-radius: 5px !important;
      font-weight: 600 !important;
      opacity: 1 !important;
    }

    .ce-chat-header-title {
      color: #ED2337 !important;
      font-size: 24px !important;
      font-weight: bold !important;
      margin-top: 5px !important;
    }

    .ce-chat-header-subtitle {
      font-size: 16px !important;
    }

    .ce-chat-list-column {
      .ce-chat-list {
        padding-left: 10px !important;
        padding-right: 10px !important;
        background-color: #20252C !important;

        .ce-chat-card {
          margin-bottom: 4px;

          .ce-chat-card-unread {
            background-color: #ED2337 !important;
          }

          .ce-chat-card-subtitle-html {
            color: #F5F5F5 !important;
          }

          .ce-chat-card-title {
            color: #F5F5F5 !important;
            font-weight: 800 !important;
          }

          .ce-chat-card-time-stamp {
            color: #F5F5F5 !important;
          }

          &.ce-active-chat-card {
            background-color: #2D3339 !important;
          }

          &.ce-hovered-chat-card {
            background-color: #2D3339 !important;
          }
        }

        .ce-chat-form {
          background-color: #20252C !important;

          .ce-default-button.ce-chat-form-button {
            display: none; /*Feature Hidden*/
            background-color: rgb(245, 34, 45) !important;
            color: white !important;
            border: transparent !important;
            padding: 2px 13px !important;
          }

          .ce-text-input {
            display: none; /*Feature Hidden*/
            background-color: #18242f;
            border: 1px solid #EE2337 !important;
            color: white !important;

          }
        }
      }
    }

    .ce-chat-feed-column {
      background-color: #20252C !important;
      overflow-y: hidden !important;
      border-right: 1px solid #ED2337 !important;
      border-left: 1px solid #ED2337 !important;
    }

    #ce-send-message-button {
      background-color: #2E363C !important;
      padding: 10px 20px !important;
      text-transform: uppercase;
      font-weight: 700 !important;
      font-size: 14px !important;
      bottom: 10px !important;
    }

    .ce-chat-feed {
      background-color: #20252C !important;

      .ce-message-form {
        background-color: #20252C;
        border-radius: 5px;

        #msg-textarea {
          background-color: #252A31 !important;
          border: 1px solid #A1A4A7 !important;
          border-radius: 5px !important;
          height: 100% !important;
          width: calc(100% - 32px - 95px) !important;
          resize: none !important;
          box-sizing: border-box !important;
          padding: 8px !important;
          margin-left: 16px;
          margin-right: 16px;
        }
      }

      .ce-their-message {
        .ce-their-message-body {
          margin-left: 10px !important;
        }
        .ce-avatar {
          display: none !important;
        }
      }
      .ce-their-message-sender-username {
        color: rgba(58, 26, 36, 0.5) !important;
      }

      .ce-message-date-text {
        color: rgba(255, 255, 255, 0.35) !important;
      }

      .ce-their-message-timestamp {
        color: rgba(234, 234, 234, 0.35) !important;
      }

      .ce-message-form-input {
        background: transparent !important;
        border: none !important;
        caret-color: white;
        color: white !important;
      }

      .ce-attachment-input {
        display: none !important; /*Feature Hidden*/
      }

      .ce-attachment-icon {
        display: none; /*Feature Hidden*/
        color: white !important;
        background: none !important;
        border: none !important;
      }

      .ce-chat-header {
        background-color: #393F44 !important;
        color: #D9D9D9 !important;
        padding-top: 4px !important;
        padding-bottom: 4px !important;
      }

      .ce-message-list {
        background-color: #20252C !important;

        .ce-message-list-content {
          padding-right: 5px;
          padding-left: 5px;

          .ce-my-message {
            .ce-my-message-body {
              background-color: #393F44 !important;
              margin-bottom: 12px !important;
              border-radius: 15px !important;
            }

            .ce-my-message-timestamp {
              color: #D9D9D9 !important;
              margin-top: 50px !important;
            }

            .ce-my-message-sending-body {
              background-color: rgba(238, 35, 55, 0.25) !important;
            }
          }

          .ce-their-message {
            .ce-their-message-body {
              background-color: #9B2333 !important;
              color: #D9D9D9 !important;
              border-radius: 15px !important;
              margin-bottom: 12px !important;
            }

            .ce-their-reads-row {
            }
          }
        }
      }
    }

    .ce-chat-settings {
      background-color: #20252C !important;

      .ce-chat-avatars {
        padding-top: 10px !important;
      }

      .ce-chat-settings-title {
        font-size: 18px !important;
        margin-bottom: 10px;
        color: #ED2337 !important;
        font-weight: bold !important;
      }

      .ce-autocomplete-input {
        display: none; /*Feature Hidden*/
        background-color: #20252C;
        border: 1px solid rgba(240, 240, 240, 0.37) !important;
        color: white !important;
        margin-bottom: 10px;
      }

      .ce-dropdown-label {
        color: #AAA6A8;
        font-weight: 600 !important;
        background-color: #20252C !important;
      }

      .ce-dropdown {
        border-top: none !important;

        &#ce-settings-photos-dropdown {
          display: none; /*Feature Hidden*/
        }

        .ce-danger-button {
          color: #9B2333;
          border-radius: 5px !important;
          font-weight: 600 !important;
          border: 2px solid #EE2337 !important;
          height: 45px !important;
          background-color: #252A31 !important;

          &.ce-member-delete-button {
            background-color: rgba(213, 213, 213, 0) !important;
            padding: 0 0px !important;
            border: 2px solid #EE2337 !important;
            color: #EE2337 !important;
            font-weight: bold !important;
            height: 25px !important;
            border-radius: 500px !important;
            width: 25px !important;
            top: 10px !important;

            svg {
              margin-bottom: 5px !important;
            }
          }

          &:hover {
            color: #101820 !important;
            font-weight: 600 !important;
            opacity: 1 !important;
            background-color: #EE2337 !important;
          }
        }
      }
    }

    /* ===== Mobile Settings ==== */
    .ce-mobile-chat-settings-button {
      background-color: #20252C !important;
      color: #ED2337 !important;
      border-color: #ED2337 !important;
    }

    .ce-mobile-chat-list-button {
      background-color: #20252C !important;
      color: #ED2337 !important;
      border-color: #ED2337 !important;
    }

    .ce-default-button {
      background-color: #20252C !important;
      color: #ED2337 !important;
      border-color: #ED2337 !important;
    }

    .ce-chat-list {
      background-color: #20252C !important;
    }

    .ce-active-chat-card {
      background-color: #252A31 !important;
    }

    .ce-chat-form {
      background: #20252C !important;

      .ce-chat-card {
        background-color: #20252C;
      }

      .ce-chat-form-button {
        display: none;
      }
    }
    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
      scrollbar-width: auto;
      scrollbar-color: #20252C;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      width: 8px;
    }

    *::-webkit-scrollbar-track {
      background: #20252C;
    }

    *::-webkit-scrollbar-thumb {
      background-color: rgba(238, 35, 55, 0.5);
      border-radius: 12px;
      border: 0px solid #ffffff;
    }
  }
}
