.admin-page {
  .table {
    color: #D9D9D9;
  }

  .nav-pills .nav-link.active {
    background: #EE2337;
    border: none;
    border-radius: 5px;
    font-weight: 500;
    color: #D9D9D9;
  }

  .dashboard-sidebar {
    .nav-link {
      color: #EE2337;
    }
  }

  .my-wallet-table {
    thead, tbody, tfoot, tr, td, th {
      border-bottom: 1px solid rgba(255, 255, 255, 0);
    }

    .table-border-bottom {
      padding-bottom: 15px;
    }
  }

  .my-wallet-row {
    display: flex;
    justify-content: space-between;

    .my-wallet-title {
      font-weight: 500;
      color: #EE2337;
    }

    .dashboard-secondary-btn {
      margin-left: 2.5%;
      width: 200px;
    }
  }

  @media (max-width: 442px) {
    .my-wallet-row {
      display: block;

      .dashboard-secondary-btn {
        margin-left: 0%;
        margin-top: 4%;
      }
    }
  }
}
