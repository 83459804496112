.coach-overview-tab {
  .dashboard-padding-box {
    .row-border-bottom {
      border-bottom: 1px solid rgba(238, 35, 55, 0.1);
    }
  }

  .social-links-section {
    .social-link-item {
      a {
        text-decoration: none;
        color: #D9D9D9;
      }

      a.social-link-available {
        color: #05B78C;
      }
    }
  }

  .coach-info-row {
    display: flex;

    .coach-detail-row {
      display: flex;
    }

    .coach-details {
      margin-left: 10%;
    }

    .dashboard-coach-img img {
      border-radius: 50px;
    }

    .coach-name {
      font-size: 25px;
      font-weight: 600;
    }

    .coach-role {
      font-size: 18px;
      text-transform: capitalize;
    }

    .booking-mode {
      .form-check-input {
        border: 2px solid #EE2337;
      }

      .form-check-input:checked {
        border: 2px solid #05B78C;
      }

      .form-switch .form-check-input {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
      }
    }
  }


  thead, tbody, tfoot, tr, td, th {
    border-bottom: 1px solid rgba(255, 255, 255, 0);
    padding-top: 15px;
    padding-bottom: 0px;
  }

  .coach-overview-info {
    display: flex;
  }

  .coach-contact-info-main {
    height: 90%;
  }

  .find-paypal-id {
    a {
      text-decoration: none;
      color: #05B78C;
      font-weight: 600;
    }
  }

  .paypal {
    cursor: pointer;
    border: 2px solid #05B78C;
    border-radius: 5px;
    width: 120px;

    img {
      margin: 0 auto;
      display: block;
    }
  }


  .paypal:hover {
    filter: drop-shadow(0 0 0.75rem rgba(5, 183, 140, 0.4));
  }

  @media (max-width: 1199.5px) {
    .coach-contact-info-main {
      padding-left: 0%;
      border-left: 0px solid rgba(238, 35, 55, 0.4);
    }
  }

  .coach-info-row {
    display: flex;

    a {
      color: #EE2337;
    }

    .info-title {
      width: 100px;
    }

    .info-data {
      width: 100%;

      a {
        color: #05B78C;
      }

      .unlinked-icons {
        color: #D9D9D9;
      }
    }

    .dashboard-coach-img img {
      border-radius: 50px;
      border: 3px solid #101820;
      width: 100px;
      height: 100px;
      object-fit: cover;
    }

    .coach-name {
      font-size: 25px;
      font-weight: 600;
    }

    @media (max-width: 460px) {
      .dashboard-coach-img img {
        width: 75px;
      }

      .coach-name {
        font-size: 20px;
      }
    }

    .coach-role {
      font-size: 18px;
      text-transform: capitalize;
    }

    .booking-mode {
      .form-check-input {
        border: 2px solid #05B78C;
      }

      .form-check-input:checked {
        border: 2px solid #EE2337;
      }

      .form-switch .form-check-input {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
      }
    }
  }

  .dashboard-overview-btn-main {
    display: flex;
    justify-content: right;
  }

  @media (max-width: 1199.5px) {
    .dashboard-overview-btn-main {
      display: block;
      justify-content: unset;
    }
  }

  @media (max-width: 767.5px) {
    .coach-info-row {
      display: block;

      .coach-details {
        margin-left: 0%;
        margin-top: 5%;
      }
    }
  }
}
