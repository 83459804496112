.notification-page {
  .notification-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.04);
    padding: 15px;
    margin: 10px 0;
    border-radius: 10px;

    .notification-title {
      font-size: 16px;
      font-weight: bold;
    }

    .notification-body {
      font-size: 14px;

    }

    .notification-date {
      font-size: 12px;
      color: #999;
    }

    .btn-close {
      color: white;
    }
  }
}
