.privacy-policy {
  .dashboard-box-outline {
    .terms-condition-text-main {
      text-align: justify;

      p, li {
        padding-bottom: 15px;
        font-size: 15px;
      }

      h5 {
        padding-bottom: 15px;
      }

      h4 {
        font-weight: 600;
      }

      .terms-condition-bold {
        text-transform: capitalize;
        font-weight: 700;
      }

      a {
        text-decoration: none;
        color: #EE2337;
        font-weight: 600;
      }

      table th, td, tr {
        border: 1px solid #EE2337;
      }

      th, td {
        padding: 10px 10px 10px 10px;
      }
    }

  }
}