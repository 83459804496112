.student-overview-tab {
  .right-border {
    border-right: 1px solid rgba(238, 35, 55, 0.4);
  }

  .student-contact-info-main {
    padding-left: 10%;
    border-left: 1px solid rgba(238, 35, 55, 0.4);
    height: 90%;
  }

  @media (max-width: 1199.5px) {
    .student-contact-info-main {
      padding-left: 0%;
      border-left: 0px;
    }
  }

  .student-info-row {
    display: flex;

    .info-title {
      width: 100px;

      a.social-link-available {
        color: #05B78C;
      }

      a {
        color: #D9D9D9;
      }
    }

    .info-data {
      width: 100%;

      a {
        color: #05B78C;
      }

      .unlinked-icons {
        color: #D9D9D9;
      }
    }

    .dashboard-student-img img {
      border-radius: 50px;
      border: 3px solid #101820;
      width: 100px;
    }

    .student-name {
      font-size: 25px;
      font-weight: 600;
    }

    @media (max-width: 405.5px) {
      .dashboard-student-img img {
        width: 75px;
      }

      .student-name {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .student-role {
      font-size: 18px;
      text-transform: capitalize;
    }

    .booking-mode {
      .form-check-input {
        border: 2px solid #05B78C;
      }

      .form-check-input:checked {
        border: 2px solid #EE2337;
      }

      .form-switch .form-check-input {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
      }
    }
  }

  thead, tbody, tfoot, tr, td, th {
    border-bottom: 1px solid rgba(255, 255, 255, 0);
    padding-top: 15px;
    padding-bottom: 0px;
  }

  .dashboard-chart-section {
    position: relative;

    .dashboard-chart-overlay {
      z-index: +500;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0%;
      left: 0%;
      border-radius: 5px;
      background: rgba(16, 24, 32, 0.5);

      h2 {
        text-align: center;
        position: relative;
        top: 40%;
        font-weight: 600;
        color: #D9D9D9;
        font-size: 50px;
      }
    }

    .dashboard-blur {
      filter: blur(5px);
    }

    .chart-main {
      .chart-title {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
      }

      .dashboard-chart {
        margin-top: 10%;
      }
    }

  }
}